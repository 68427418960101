import { getDataMapper, mapField } from "@/shared";

export const mapDirectorFiltersToServer = getDataMapper(
    {
        from: mapField("period", (item) => item.from),
        to: mapField("period", (item) => item.to),
        director_funnel: mapField("funnel"),
        director_status: mapField("status"),
        director_tariff: mapField("tariff"),
        tariff_period: mapField("tariffPeriod"),
        director_sphere: mapField("sphere"),
        scheduled_call: mapField("plannedCall", Number),
        email_not_confirmed: mapField("emailConfirmed", Number),
        active_subscribe: mapField("activeSubscribe", Number),
        active_test: mapField("activeTest", Number),
        payments_count: mapField("paymentsCount", Number),
        last_payment_date: mapField("lastPaymentDate", (item) => ({
            from: item.from,
            to: item.to,
        })),
        inactive_subscribe: mapField("inactiveSubscribe", Number),
    },
    {
        shouldAvoidUndefined: true,
        shouldAvoidNull: true,
    },
);
